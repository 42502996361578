@import '~antd/dist/antd.css';

/* Global Changes */

.default-btn {
  background: #ffffff;
}

.ant-table-cell {
  text-align: center !important;
  border: 1px solid #f0f0f0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px !important;
}

.ant-table-thead > tr > th {
  font-weight: 700;
}

.ant-select {
  font-size: 12px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: none;
  border: none;
}

.ant-select-arrow {
  color: #000000;
  font-size: 24px;
  margin-top: -13px;
}

.export-button {
  margin: 10px;
}

.ant-btn {
  margin-right: 10px;
}
