.header {
  font-weight: bold;
  font-size: 22px;
  background: #fafafa;
  padding: 10px 0;
}

.exportSection {
  padding: 0px 15px;
  display: flex;
  border: 1px solid;
  float: right;
  margin: 20px;
  font-size: 18px;
  cursor: pointer;
}
