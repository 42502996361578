.heading {
  font-weight: bold;
  font-size: 22px;
  background: #fafafa;
  padding: 10px 0;
}
.textCenter {
  text-align: center;
}

.title {
  text-align: center;
  font-weight: bold;
  background: #fafafa;
  padding: 10px 0;
}

.accountSection {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.details {
  margin-left: 100px;
  width: 40%;
}

.detailsTable {
  width: 100%;
  margin-bottom: 10px;
}

.buttonSection {
  margin-left: auto;
  margin-top: 70px;
  padding-right: 20px;
}

hr {
  margin: 10px 0 20px;
}

.simpleTable {
  margin-top: 10px;
}
.simpleTable th {
  text-align: center !important;
  border: 2px solid #f0f0f0;
  padding: 10px;
  background-color: #fafafa;
}
.simpleTable td {
  padding: 5px;
  border: 2px solid #f0f0f0;
  margin: 0;
}

.buttonContainer {
  padding: 20px 0;
  float: right;
}

.hide {
  display: none;
}
