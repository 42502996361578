.flex {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
  background: #fafafa;
}

.width50 {
  width: 50%;
}
.textRight {
  text-align: right;
}
.textRightButton {
  margin-top: 15px;
}
.header {
  font-weight: bold;
  font-size: 22px;
  padding: 10px 0;
}

.exportSection {
  padding: 0px 15px;
  display: flex;
  border: 1px solid;
  float: right;
  margin: 20px;
  font-size: 18px;
  cursor: pointer;
}
